import { ReactComponent as Favorite } from 'app-images/icons/favorites.svg'
import { ReactComponent as Metamask } from 'app-images/icons/metamask.svg'
import { ReactComponent as Twitter } from 'app-images/icons/twitter.svg'
import classNames from 'classnames'
import React, { FC, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addFavorite, removeFavorite } from '../../actions/tokenActions'
import { setHeaderFavoriteAnimationActive } from '../../actions/uiActions'
import { AmplitudeEvent } from '../../constants/amplitudeEvents'
import { TokenV3 } from '../../model'
import { State } from '../../reducers'
import amplitudeService from '../../services/amplitudeService'
import { getMarketDisplayName } from '../../utils'
import { buildTokenTweet, buildTweetUrl } from './resources'

interface SuccessModalLinksProps {
  token: TokenV3
  onClickFavorite: (isFavorite: boolean, id: string) => void
  showMetamask: boolean
  addToMetaMask: (token: TokenV3) => void
}

const SuccessModalLinks: FC<SuccessModalLinksProps> = ({
  token,
  onClickFavorite,
  showMetamask,
  addToMetaMask,
}) => {
  const symbol = token.symbols ? getMarketDisplayName(token) : 'token'
  const { favorites } = useSelector((store: State) => store.tokens)
  const heartInitialRef = useRef<SVGSVGElement>(null)
  const heartIconRef = useRef<HTMLElement>(null)
  const reduxDispatch = useDispatch()

  const isFavoriteToken = !!favorites.find((tokenId) => tokenId === token?.id)

  const { isHeaderFavoriteAnimationActive } = useSelector((store: State) => store.ui)

  const doFavoriteToken = async (token: TokenV3): Promise<void> => {
    const wasTokenFavorite = favorites?.find((tid: string) => tid === token.id) || false
    const tokenName = getMarketDisplayName(token)
    if (wasTokenFavorite) {
      amplitudeService.removedFavorite(token.id, tokenName, token.network)
      reduxDispatch(removeFavorite(token.id))

      reduxDispatch(setHeaderFavoriteAnimationActive(false))
      if (heartIconRef.current) {
        heartIconRef.current.style.cssText = ''
      }
    }

    if (!wasTokenFavorite) {
      amplitudeService.addedFavorite(token.id, tokenName, token.network, true)
      reduxDispatch(addFavorite(token.id))

      const target = document.querySelector('.header .favorites-target')
      reduxDispatch(setHeaderFavoriteAnimationActive(true))

      if (target && heartInitialRef.current && heartIconRef.current) {
        const coordStart = heartInitialRef.current.getBoundingClientRect()
        const coordEnd = target.getBoundingClientRect()

        heartIconRef.current.style.cssText = `
            --start-x: ${coordStart.left}px;
            --start-y: ${coordStart.top}px;
            --end-x: ${coordEnd.left}px;
            --end-y: ${coordEnd.top}px;
          `
      }
    }
  }

  const handleClickFavorite = (token: TokenV3): void => {
    doFavoriteToken(token)
    onClickFavorite(!isFavoriteToken, token.id)
  }

  const addToMetaMaskHandler = (): void => {
    amplitudeService.sendEvent(AmplitudeEvent.ADD_TO_METAMASK_FROM_POST_SWAP)
    addToMetaMask(token)
  }

  return (
    <div className="card__body" data-testid="support-links">
      <div className="row row--gap-md">
        {showMetamask && (
          <div className="cell">
            <button
              className="confirm-modal-button"
              data-testid="metamask-success-link"
              onClick={addToMetaMaskHandler}>
              <span className="confirm-modal-button__icon">
                <Metamask width={40} height={40} />
              </span>
              <span className="confirm-modal-button__title">Add {symbol} to Metamask</span>
            </button>
          </div>
        )}
        <div className="cell">
          <button
            className={classNames('confirm-modal-button', 'confirm-modal-button--favorite', {
              active: isFavoriteToken,
            })}
            data-testid="favorite-success-link"
            onClick={(): void => {
              handleClickFavorite(token)
            }}>
            <span className="confirm-modal-button__icon" ref={heartIconRef}>
              {isFavoriteToken && isHeaderFavoriteAnimationActive && (
                <Favorite className="animate" />
              )}
              <Favorite width={40} height={40} ref={heartInitialRef} />
            </span>
            <span className="confirm-modal-button__title">
              <span className="caption">
                <span className="add">Add {symbol} to favorites</span>
                <span className="remove">Remove {symbol} from favorites</span>
              </span>
            </span>
          </button>
        </div>
        <div className="cell">
          <a
            href={buildTweetUrl(buildTokenTweet(token))}
            onClick={(): void =>
              amplitudeService.sendEvent(AmplitudeEvent.OPENED_TWITTER_FROM_POST_SWAP)
            }
            target="_blank"
            rel="noopener noreferrer">
            <button className="confirm-modal-button" data-testid="favorite-success-link">
              <span className="confirm-modal-button__icon">
                <Twitter width={40} height={40} />
              </span>
              <span className="confirm-modal-button__title">
                Shill {symbol} to&nbsp;your Twitter&nbsp;Frens!
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default SuccessModalLinks
