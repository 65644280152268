import { useWeb3React } from '@telekomconsalting/core'
import BigNumber from 'bignumber.js'
import React, { FC, useRef } from 'react'
import Modal from 'react-modal'
import { useDispatch } from 'react-redux'

import { addFavorite, removeFavorite } from '../../actions/tokenActions'
import { getRootElement } from '../../helpers/appHelpers'
import gif1 from '../../images/animation/loader_shark.gif'
import gif2 from '../../images/animation/loader_turtle.gif'
import gif3 from '../../images/animation/loader_whale.gif'
import { ReactComponent as IconClose } from '../../images/icons/close.svg'
import { NetworkConfigV3, TokenV3, TokenWithApprovalContext } from '../../model'
import { playFavoriteSound, playUnfavoriteSound } from '../../services/soundService'
import { addToMetaMask } from '../../services/tokenService'
import NetworkFeeDesktop from '../NetworkFee/NetworkFeeDesktop'
import SwapSummary from '../SwapSummary'
import TipSummary from '../TipOptions/TipSummary'
import { feeTooltipText } from './resources'
import SuccessModalLinks from './SucessModalLinks'
import ViewOn from './ViewOn'

const gifs = [gif1, gif2, gif3]

interface SuccessModalProps {
  onClose: () => void
  fromAmount: BigNumber
  fromTokenUsdPrice: BigNumber
  gasCostInNativeToken?: BigNumber
  gasCosts?: BigNumber
  networkConfig: NetworkConfigV3
  tip?: number
  hashTxn?: string
  tokenFrom: TokenWithApprovalContext
  tokenTo: TokenWithApprovalContext
  toAmount: BigNumber
  toTokenUsdPrice: BigNumber
}

const SuccessModal: FC<SuccessModalProps> = ({
  onClose,
  tokenFrom,
  tokenTo,
  networkConfig,
  toAmount,
  fromAmount,
  fromTokenUsdPrice,
  toTokenUsdPrice,
  gasCostInNativeToken,
  gasCosts,
  tip,
  hashTxn,
}) => {
  const { library } = useWeb3React()
  const reduxDispatch = useDispatch()
  const showGif = useRef<string>(gifs[Math.floor(Math.random() * gifs.length)])

  const onClickFavorite = (isFavorite: boolean, id?: string): void => {
    if (!id) {
      return
    }

    if (isFavorite) {
      reduxDispatch(addFavorite(id))
      playFavoriteSound(false)
    } else {
      reduxDispatch(removeFavorite(id))
      playUnfavoriteSound(false)
    }
  }

  const addToMM = async (token?: TokenV3): Promise<void> => {
    await addToMetaMask(library, token)
  }

  return (
    <Modal
      parentSelector={getRootElement}
      isOpen={true}
      onRequestClose={onClose}
      overlayClassName="modal-overlay"
      className="modal-content">
      <div className=" modal confirm-modal">
        <div className="modal__close" onClick={(): void => onClose()}>
          <IconClose />
        </div>
        <div className="modal__header">
          <h4 className="modal__title">Token Swap Successful!</h4>
        </div>
        <div className="modal__body">
          <div className="modal__overlay">
            <img className="modal__overlay-image" src={showGif.current} alt="success" />
          </div>
          <SwapSummary
            tokenFrom={tokenFrom}
            tokenTo={tokenTo}
            tokenNetwork={networkConfig.name}
            fromAmount={fromAmount}
            toAmount={toAmount}
            fromAmountSelectedCurrency={fromAmount.times(fromTokenUsdPrice)}
            toAmountSelectedCurrency={toAmount.times(toTokenUsdPrice)}
            readonly={true}
          />
          <div className="card card--dark">
            <div className="card__body">
              <div className="confirm-modal__card-line">
                {gasCosts && gasCostInNativeToken && (
                  <NetworkFeeDesktop
                    title="Transaction Fee Paid:"
                    tooltipText={feeTooltipText}
                    tokenNetwork={networkConfig.name}
                    gasCosts={gasCosts}
                    gasCostInNativeToken={gasCostInNativeToken}
                    readonly={true}
                    fromAmountSelectedCurrency={fromAmount.times(fromTokenUsdPrice)}
                  />
                )}
              </div>
              <div className="confirm-modal__card-line">
                <TipSummary tip={tip || 0} tokenTo={tokenTo} toAmount={toAmount} />
              </div>
              {networkConfig && hashTxn && (
                <div className="confirm-modal__card-line">
                  <ViewOn hashTxn={hashTxn} networkConfig={networkConfig} />
                </div>
              )}
            </div>
          </div>
          <div className="card card--clear">
            <div className="card__header">
              <h4 className="card__title">What's next?</h4>
            </div>
            <SuccessModalLinks
              token={tokenTo}
              onClickFavorite={onClickFavorite}
              showMetamask={!!library?.currentProvider?.isMetaMask}
              addToMetaMask={addToMM}
            />
          </div>
        </div>
        <div className="modal__footer">
          <div className="row row--justify-center">
            <div className="cell cell--auto">
              <button
                className="modal__action button button--lg button--secondary"
                onClick={onClose}>
                <span className="caption">Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SuccessModal
