import { useWeb3React } from '@telekomconsalting/core'
import { model } from '@telekomconsalting/dex-guru-model'
import BigNumber from 'bignumber.js'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { referrerAddress } from '../../config/settings'
import {
  GasPrice,
  GasPriceEIP1559,
  StatefulTransaction,
  TokenWithApprovalContext,
} from '../../model'
import { State } from '../../reducers'
import { findAmounts } from '../../services/transactionLogService'
import FailedTxnModal from './FailedTxnModal'
import SuccessModal from './SuccessModal'

interface SuccessModalProps {
  isOpen: boolean
  onClose: () => void
  trade: StatefulTransaction
  type: 'success' | 'failed'
}

const TxnCompleteModal: FC<SuccessModalProps> = ({ isOpen, onClose, trade, type }) => {
  const tokens = useSelector((state: State) => state.tokens.tokens)
  const settings = useSelector((state: State) => state.settings.settingsData)
  const [tokenFrom, setTokenFrom] = useState<TokenWithApprovalContext | undefined>()
  const [tokenTo, setTokenTo] = useState<TokenWithApprovalContext | undefined>()
  const [fromAmount, setFromAmount] = useState<BigNumber | undefined>()
  const [toAmount, setToAmount] = useState<BigNumber | undefined>()
  const [networkConfig, setNetworkConfig] = useState<model.NetworkConfigV3 | undefined>()
  const networksConfigs = useSelector((state: State) => state.networksConfig.data)
  const [gasCosts, setGasCosts] = useState<BigNumber | undefined>()
  const [gasCostInNativeToken, setGasCostInNativeToken] = useState<BigNumber | undefined>()
  const [fromTokenUsdPrice, setFromTokenUsdPrice] = useState(new BigNumber(0))
  const [toTokenUsdPrice, setToTokenUsdPrice] = useState(new BigNumber(0))
  const { account, library } = useWeb3React()

  useEffect(() => {
    if (trade.tokenNetwork) {
      const networkConfig = networksConfigs.find((n) => n.name === trade.tokenNetwork)

      if (networkConfig) {
        setNetworkConfig(networkConfig)
      }
    }
  }, [trade.tokenNetwork, networksConfigs])

  useEffect(() => {
    if (trade.fromTokenAddress && trade.tokenNetwork) {
      const tokenFrom = tokens.find(
        (t) => t.id === `${trade.fromTokenAddress}-${trade.tokenNetwork}`
      )
      setTokenFrom(tokenFrom)

      if (tokenFrom) {
        setFromTokenUsdPrice(new BigNumber(tokenFrom.priceUSD))
      }
    }
  }, [trade.fromTokenAddress, tokenFrom?.priceUSD, trade.tokenNetwork, tokens])

  useEffect(() => {
    if (trade.toTokenAddress && trade.tokenNetwork) {
      const tokenTo = tokens.find((t) => t.id === `${trade.toTokenAddress}-${trade.tokenNetwork}`)
      setTokenTo(tokenTo)

      if (tokenTo) {
        setToTokenUsdPrice(new BigNumber(tokenTo.priceUSD))
      }
    }
  }, [trade.toTokenAddress, tokenTo?.priceUSD, trade.tokenNetwork, tokens])

  useEffect(() => {
    const readLogs = (): void => {
      if (library && account) {
        const zeroXProxyAddress = networkConfig && networkConfig.zerox_api.spender_address
        if (trade.receipt && zeroXProxyAddress) {
          const amounts = findAmounts(
            library,
            trade,
            account,
            zeroXProxyAddress,
            referrerAddress,
            settings.slippage,
            tokenFrom,
            tokenTo
          )
          setFromAmount(amounts.fromAmount)
          setToAmount(amounts.toAmount)
        }
      }
    }

    const calculateGas = (): void => {
      if (trade.receipt?.gasUsed && networkConfig && trade.gasPrice && trade.gasFeeType) {
        const gasPriceWei =
          trade.gasPrice[trade.gasFeeType] instanceof BigNumber
            ? (trade.gasPrice as GasPrice)[trade.gasFeeType]
            : trade.receipt?.effectiveGasPrice ||
              (trade.gasPrice as GasPriceEIP1559)[trade.gasFeeType].maxFee
        const gasCostInNativeToken = new BigNumber(trade.receipt.gasUsed)
          .times(gasPriceWei)
          .div(10 ** networkConfig.native_token.decimals)

        const nativeToken = tokens.find((t) => t.id === networkConfig.native_token.id)
        setGasCostInNativeToken(gasCostInNativeToken)

        if (nativeToken) {
          setGasCosts(gasCostInNativeToken.times(nativeToken.priceUSD))
        }
      }
    }

    if (fromAmount === undefined && toAmount === undefined) {
      readLogs()
    }

    calculateGas()
  }, [
    trade,
    library,
    tokenFrom,
    tokenTo,
    networkConfig,

    tokens,
    account,
    fromAmount,
    toAmount,
    settings.slippage,
  ])

  if (!isOpen) {
    return <div />
  }

  if (!trade.tokenNetwork || !tokenFrom || !tokenTo || !networkConfig) {
    return null
  }

  switch (type) {
    case 'success': {
      if (fromAmount === undefined || toAmount === undefined) {
        return null
      } else {
        return (
          <SuccessModal
            onClose={onClose}
            fromAmount={fromAmount}
            fromTokenUsdPrice={fromTokenUsdPrice}
            gasCostInNativeToken={gasCostInNativeToken}
            gasCosts={gasCosts}
            networkConfig={networkConfig}
            hashTxn={trade.hashTxn}
            tip={trade.tip}
            toAmount={toAmount}
            tokenFrom={tokenFrom}
            toTokenUsdPrice={toTokenUsdPrice}
            tokenTo={tokenTo}
          />
        )
      }
    }
    case 'failed':
      return (
        <FailedTxnModal
          onClose={onClose}
          fromAmount={trade.amount}
          fromTokenUsdPrice={fromTokenUsdPrice}
          gasCostInNativeToken={gasCostInNativeToken}
          gasCosts={gasCosts}
          networkConfig={networkConfig}
          hashTxn={trade.hashTxn}
          toAmount={trade.estimatedAmount}
          tokenFrom={tokenFrom}
          toTokenUsdPrice={toTokenUsdPrice}
          tokenTo={tokenTo}
          txnError={trade.txnError}
        />
      )

    default:
      return null
  }
}

export default TxnCompleteModal
